import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Playbutton from '../components/Playbutton'
import '../style/App.scss';


export default class Soundboard extends Component {
    constructor(props) {
        super(props);
        this.audiofiles = [require("../assets/audio/goenni.ogg"), require("../assets/audio/croquebude.ogg"), require("../assets/audio/liebe.ogg"), require("../assets/audio/blubb.ogg"), require("../assets/audio/yiiah.ogg"), require("../assets/audio/uhyeah.ogg"), require("../assets/audio/jaman.ogg"), require("../assets/audio/diggidienstag.ogg"), require("../assets/audio/whatup.ogg"),require("../assets/audio/love1.ogg"), require("../assets/audio/love2.ogg"), require("../assets/audio/love3.ogg"), require("../assets/audio/uhsquam.ogg"), require("../assets/audio/grooveskickin.ogg"),require("../assets/audio/yawesra.ogg"), require("../assets/audio/hehehe.ogg"), require("../assets/audio/yaawwesra.ogg"), require("../assets/audio/uhdigga.ogg"), require("../assets/audio/pokemon.ogg"), require("../assets/audio/wasjetzt.ogg"), require("../assets/audio/jamankim.ogg"), require("../assets/audio/dreckigelache.ogg"), require("../assets/audio/jawauri.ogg"), require("../assets/audio/mmhhmm.ogg"), require("../assets/audio/yawpaul.ogg"), require("../assets/audio/jiaw.ogg"), require("../assets/audio/bowchicka.ogg"), require("../assets/audio/fourtwenty.m4a")];
    }
    componentDidMount() {
        window.scrollTo(0,0);
    }

    renderButtons() {
        let audiofiles = this.audiofiles.sort();
        let buttons = [];

        for(let i=0; i < audiofiles.length; i++) {
            let text = audiofiles[i].split("/").pop();
            let regex = /^[^.]+/g;
            let buttonText = text.match(regex);

            buttons.push(
                <Playbutton audiofile={audiofiles[i]} fileId={"fileId" + i} buttonText={buttonText} key={buttonText + i} />
            )
        }
        return (buttons)
    }

    render() {
        return (
            <div className="contentbox">
                <Link to="/">Zurück zur Startseite</Link>
                <br />
                <div className="soundboard">
                    {this.renderButtons()}
                </div>
            </div>
        );
    }
}
