import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link} from 'react-router-dom';
import logo from '../assets/slplogo2.png';
import '../style/navigation.scss';

export default class Navigation extends Component {
    // constructor(props) {
    //     super(props);
    // }

  render() {
    return (
        <nav className="navigation">
          <div className="navigation__logo">
            <Link to="/"> <img className="logo" src={logo} alt="SLP Show Love Project" /></Link>
          </div>
            <div className="navigation__links">
                <AnchorLink offset='40' href='#dates'>Konzerte</AnchorLink>
                <AnchorLink offset='40' href='#listen'>Hören</AnchorLink>
                <AnchorLink offset='40' href='#videos'>Videos</AnchorLink>
                {/* <Link to="/soundboard">Soundboard</Link> */}
                {/* <AnchorLink offset='56' href='#dates'>Konzerte</AnchorLink> */}
            </div>
      </nav>
    );
  }
}
