import React, { Component } from 'react';

class RandomSongButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      randomSongName: ""
    }
    this.setRandomSong = this.setRandomSong.bind(this);
  }

  setRandomSong() {
    let songs = ["Yo Love", "Let it Rain", "Stressbahn", "Fuck That", "Wir können fliegen", "Rauch auf", "Breathe Deep", "What we do", "Lone Ranger", "Von heute für immer", "Schmetterlinge", "Diggi", "420", "Ich sah dich", "Lass mal chillen", "Grooves Kickin", "Sei kein Baum", "Tropische Hitze", "Easy Life", "Unter vier Joints geht garnichts", "How", "Loving Soul", "Träumer", "Inneres Lied", "I'm so small", "Sweet Green"]
    let newSong = songs[Math.floor(Math.random()*songs.length)];
    if(newSong !== this.state.randomSongName) {
      this.setState({
        randomSongName: newSong
      })
    } else {
      this.setRandomSong()
    }
  }

  render() {
    return (
      <div>
        <button className="randomsongbutton" onClick={this.setRandomSong}>
          What's next?
        </button>
        <div >{this.state.randomSongName}</div>
      </div>
    );
  }
}
export default RandomSongButton;