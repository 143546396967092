import React from 'react';  
import '../style/playbutton.scss';

export default class RandomPlaybutton extends React.Component{
    constructor(props) {
        super(props);
        this.audiofiles = [require("../assets/audio/blubb.ogg"), require("../assets/audio/yiiah.ogg"), require("../assets/audio/uhyeah.ogg"), require("../assets/audio/jaman.ogg"), require("../assets/audio/diggidienstag.ogg"), require("../assets/audio/whatup.ogg"),require("../assets/audio/love1.ogg"), require("../assets/audio/love2.ogg"), require("../assets/audio/love3.ogg"), require("../assets/audio/uhsquam.ogg"), require("../assets/audio/grooveskickin.ogg"),require("../assets/audio/yawesra.ogg"), require("../assets/audio/hehehe.ogg"), require("../assets/audio/yaawwesra.ogg"), require("../assets/audio/uhdigga.ogg")];       
        this.aud_play_pause = this.aud_play_pause.bind(this);
        this.renderAudioFiles = this.renderAudioFiles.bind(this);
        this.getRandomNumber = this.getRandomNumber.bind(this);

        this.state = {
            nextFileToPlay: this.getRandomNumber()
        }
    }

    getRandomNumber() {
        let num = Math.floor(Math.random() * this.audiofiles.length);
        // console.log(num);
        return num;  
    }

    aud_play_pause() {
        var myAudio = document.getElementById("soundFile" + this.state.nextFileToPlay);
        // if (myAudio.paused) {
        myAudio.play();
        // } else {
        //     myAudio.pause();
        // }
        this.setState({
            nextFileToPlay: this.getRandomNumber()
        })
    }

    renderAudioFiles() {
        let audiofiles = this.audiofiles;

        let audioElements = [];

        for(let i=0; i < audiofiles.length; i++) {
            audioElements.push(<audio id={"soundFile" + i} key={i} preload="none" type="audio/ogg" ><source src={audiofiles[i]} /></audio>)
        }

        return (
            <React.Fragment>
                {audioElements}
            </React.Fragment>
        )
    }

    render () {
        return(
            <div>
                {this.renderAudioFiles()}
                {!this.props.useChildren && <a className="playbutton playbutton--rotate" data-toggle="button" onClick={this.aud_play_pause}><span>{this.props.buttonText}</span></a>}
                {this.props.useChildren && <a data-toggle="button" onClick={this.aud_play_pause}>{this.props.children}</a>}
            </div>
        );
    }
}
