import React, { Component } from 'react';
// import coverfront from '../assets/images/coverfront.jpg';
// import img2 from '../assets/images/2.jpg';
// import { FaSpotify, FaSoundcloud, FaFacebook, FaEnvelope, FaInstagram } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faSoundcloud, faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import '../style/sociallinks.scss';

export default class Sociallinks extends Component {
  render() {
    return (
      <div className="jumbotron contentbox" >
        <h3>Find us on...</h3>
        <div className="sociallinks">
          <a href="https://www.facebook.com/ShowLoveProject/" target="_blank noopener norefferer"><FontAwesomeIcon icon={faFacebook} /></a>
          <a href="https://www.instagram.com/showloveproject/" target="_blank noopener norefferer"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="https://www.youtube.com/channel/UCQqqpsJtCJYzhsdfH-H_ywA" target="_blank noopener norefferer"><FontAwesomeIcon icon={faYoutube} /></a>
          <a href="https://soundcloud.com/show-love-project" target="_blank noopener norefferer"><FontAwesomeIcon icon={faSoundcloud} /></a>
          <a href="https://open.spotify.com/album/2uZzK5yZsExApJcve2jbNl" target="_blank noopener norefferer"><FontAwesomeIcon icon={faSpotify} /></a>
        </div>
      </div>
    );
  }
}
