import React, { Component } from 'react';
// import coverfront from '../assets/images/coverfront.jpg';
import img2 from '../assets/images/2.jpg';
// import hearts from '../assets/images/hearts.jpeg';
import spotify from '../assets/images/spotify.png';
import amazon from '../assets/images/amazon.png';
import deezer from '../assets/images/deezer.png';
import '../style/news.scss';

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpotifyPlayer: false
    }
    this.setShowSpotifyPlayer = this.setShowSpotifyPlayer.bind(this);
  }

  setShowSpotifyPlayer() {
    this.setState({
      showSpotifyPlayer: !this.state.showSpotifyPlayer
    })
  }
  render() {
    let imgurlApple = "https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2018-04-24&kind=album&bubble=apple_music"
    let inlinestlyesApple = {
      display: 'inline-block',
      overflow: 'hidden',
      backgroundImage: 'url(' + imgurlApple + ')',
      backgroundRepeat: 'no-repeat',
      width: '158px',
      height: '45px'
    }

    return (
      <div className="news contentbox" style={{ backgroundImage: "url(" + img2 + ")" }} id="listen">
        <div className="news__content news__content--padding">
          <h1>
            Show Love Project
          </h1>
          <h3>EP "Kurz und Breit Erklärt"</h3>
        </div>
        <div className="news__content">
          {!this.state.showSpotifyPlayer && <div style={{ minHeight: "150px", maxWidth: "250px" }}>
            <small>
              Hier sollten ein Spotify-Player und weitere Links erscheinen. Akzeptiere bitte cookies, um alles anzuzeigen:
            </small>
            <br />
            <button className="randomsongbutton" onClick={this.setShowSpotifyPlayer}>Cookies akzeptieren & Player anzeigen</button>
          </div>}
          {this.state.showSpotifyPlayer && <iframe src="https://open.spotify.com/embed/album/6WP115vQGHMS6mQk9OrmW5" width="300" height="310" frameBorder="0" allowtransparency="true" allow="encrypted-media" title="Show Love Project on Spotify"></iframe>}
          {this.state.showSpotifyPlayer && <div className="musiclinks-container">
            <div className="musiclinks">
              <a href="https://open.spotify.com/album/2uZzK5yZsExApJcve2jbNl" target="_blank" rel="noopener noreferrer" ><img src={spotify} alt="Spotify - SLP Kurz und breit erklärt" /></a>
              <a href="https://www.amazon.de/Kurz-breit-erklärt-Show-Project/dp/B07ZLYDVWM" target="_blank" rel="noopener noreferrer" ><img src={amazon} alt="Amazon Music - SLP Kurz und breit erklärt" /></a>
              <a href="https://www.deezer.com/en/album/116374782" target="_blank" rel="noopener noreferrer" ><img src={deezer} alt="Listen on Deezer - SLP Kurz und breit erklärt" /></a>
              {/* <a href="https://geo.music.apple.com/us/album/kurz-und-breit-erkl%C3%A4rt-ep/1484961787?mt=1&app=music" target="_blank" rel="noopener noreferrer" style={inlinestlyesApple}> </a> */}
              <a style={{ margin: "0" }} href='https://play.google.com/store/music/album/Show_Love_Project_Kurz_und_breit_erkl%C3%A4rt?id=Bfcqpvlxnfyb2zuc7xc65tbe5re&gl=DE' target="_blank" rel="noopener noreferrer" ><img style={{ width: "176px" }} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' /></a>
            </div>
          </div>}

          {/* <section>
              Die Pladde zum Anfassen gibt's bei Konzerten oder am Diggi-Dienstag, also kommt vorbei und holt euch eine ab :)
              <br />
              <br />
          </section>
          <img src={coverfront} className="cdcover" alt="SLP EP Kurz und breit erklärt - Show Love Project"/> */}
        </div>
      </div>
    );
  }
}
