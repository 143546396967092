import React, { Component } from 'react';
import '../style/dates.scss';

export default class Dates extends Component {
    constructor(props) {
        super(props);

        this.createDates = this.createDates.bind(this);
    }

    createDates(dates) {
        let dateList = [];
        dates.forEach(date => {
            dateList.push(
                <div className="date" key={date.date}>
                    <div className="date__datetime">
                        <div className="date__date">{date.date}</div>
                        <div className="date__time">{date.time}</div>
                    </div>
                    <div className="date__location">{date.location}</div>
                    <div className="date__link">
                        {date.linkToEvent &&
                        <a href={date.linkToEvent} target="_blank" rel="noopener noreferrer">{date.linkText}</a>}
                        {!date.linkToEvent &&
                        <div className="date__link">{date.linkText}</div>}
                    </div>
                </div>
            )
        });
        return dateList
    }

  render() {
      let dates = [    
        //   {
        //     date: "6. Dezember",
        //     time: "20 Uhr",
        //     location: "Birdland Hamburg",
        //     linkToEvent: "https://www.tixforgigs.com/Event/32370?af=po4qmxb9e7bs&saf=u95y11p",
        //     linkText: "Tickets"
        //   },
        //   {
        //     date: "7. Dezember",
        //     time: "20 Uhr",
        //     location: "Treibsand Lübeck (Support for Guacáyo)",
        //     linkToEvent: "https://www.facebook.com/events/2382452952011122/",
        //     linkText: "zum Facebook Event"
        //   },
        ];

    return (
      <div className="dates contentbox" id="dates">
          <h3>Konzerte</h3>
          <div className="datelist">
              {this.createDates(dates)}
              Leider können wir dank Corona erstmal keine Konzerte mehr spielen. Wenn es wieder losgeht erfahrt ihr es hier.
              <br />
              <br />
              <br />
          </div>
      </div>
    );
  }
}
