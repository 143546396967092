import React from 'react';  
import '../style/playbutton.scss';

export default class Playbutton extends React.Component{
    constructor(props) {
        super(props);
        // this.audiofile = this.props.audiofile;
        this.aud_play_pause = this.aud_play_pause.bind(this);
        this.state = {
            playing: false,
            duration: 50
        }
    }

    aud_play_pause() {
        var myAudio = document.getElementById("soundFile" + this.props.fileId);
        // if (myAudio.paused) {
        myAudio.play();
        // } else {
        //     myAudio.pause();
        // }
       
       
        // let duration;
        // myAudio.addEventListener("loadedmetadata", function(_event) {
        //     duration = myAudio.duration;
        //     // myAudio.controls = true;
        //     console.log(duration);
        // });  
        // setTimeout(() => {        
        //     this.setState({
        //         duration: duration,
        //         playing: true
        //     })
        //     setTimeout(() => {
        //         this.setState({
        //             playing: false
        //         })
        //     }, duration * 1000);
        // }, 50);
    }

    render () {
        return(
            <div>
                <audio id={"soundFile" + this.props.fileId} preload="none" type="audio/ogg" ><source src={this.props.audiofile} /></audio>
                <a className="playbutton" data-toggle="button" onClick={this.aud_play_pause}><span>{this.props.buttonText}</span></a>
                {/* {this.state.playing && <div>duration: {this.state.duration}</div>} */}
            </div>
        );
    }
}
