import React, { Component } from 'react';
import Start from '../container/start';
import Impress from '../container/impress';
import Soundboard from '../container/soundboard';

//using HashRouter instead of BrowserRouter here to fix copy-pasting issues on live systems;
//see https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writting-manually
//and https://reacttraining.com/react-router/web/api/HashRouter
import {
  HashRouter as Router,
  Route, Switch
} from 'react-router-dom'
import '../style/App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
            <Switch>
                <Route exact path="/" component={Start} />
                <Route exact path="/impressum" component={Impress} />
                <Route exact path="/soundboard" component={Soundboard} />
                {/* <Route component={NotFound} /> */}
            </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
