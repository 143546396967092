import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
// import Carousel from '../components/Carousel';
import Navigation from '../components/Navigation';
import Dates from '../components/Dates';
import News from '../components/News';
import Sociallinks from '../components/Sociallinks';
import RandomPlaybutton from '../components/RandomPlaybutton';
import RandomSongButton from '../components/RandomSongButton';
// import logo from '../assets/slplogo2.png';
// import junitour from '../assets/images/junitour2019.jpg';
import turtsmall from '../assets/images/turtsmall.png';
import img2 from '../assets/images/gig1.jpg';
import { Link } from 'react-router-dom';
import '../style/App.scss';
import '../style/soundplayer.scss';

export default class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showYoutubeVideo: false
    }
    this.setShowYoutubeVideo = this.setShowYoutubeVideo.bind(this);
  }

  setShowYoutubeVideo() {
    this.setState({
      showYoutubeVideo: !this.state.showYoutubeVideo
    })
  }

  render() {
    return (
      <div className="start">
        {/* <Navigation /> */}
        <News />
        <LazyLoad height={200}>
          <div className="contentbox contentbox--youtube" id="videos">
            <h3>Show Love Project - Breathe Deep (Live im Birdland Hamburg)</h3>
            {/* <iframe className="ytplayer" id="ytplayer" type="text/html" width="80%" title="Show Love Project on Youtube"  src="https://www.youtube-nocookie.com/embed/O4EzvoFx_KI?rel=0?autoplay=0&origin=http://showloveproject.de" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe> */}
            {!this.state.showYoutubeVideo && <div style={{minHeight: "150px"}}>
              Hier sollte ein Youtube video erscheinen. Akzeptiere bitte youtube-cookies, um das Video anzuzeigen:
              <br />
              <button className="randomsongbutton" onClick={this.setShowYoutubeVideo}>Youtube-Cookie akzeptieren & Video anzeigen</button>
            </div>}
            {this.state.showYoutubeVideo && <iframe className="ytplayer" id="ytplayer" width="80%" title="Show Love Project Live im Birdland Hamburg" src="https://www.youtube.com/embed/8zedi4_Y5k8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
            {/* <iframe width="80%" height="auto" src="https://www.youtube.com/embed/zEY3qv3EYaU"></iframe> */}
          </div>
        </LazyLoad>
        {/* <LazyLoad height={200}> */}
          {/* <div className="soundplayer contentbox"> */}
            {/* <h3>
              Show Love Project auf Soundcloud
            </h3> */}
            {/* <iframe width="80%" height="200" scrolling="no" frameBorder="no" allow="autoplay" title="Show Love Project on Soundcloud" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/282773801&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe> */}
            {/* <br /><a href="https://soundcloud.com/show-love-project" target="_blank" rel="noopener noreferrer">Mehr auf Soundcloud</a> */}
            {/* <iframe className="iframe-element" scrolling="no" frameBorder="no" title="Show Love Project on Soundcloud" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/194329135&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false"></iframe> */}
          {/* </div> */}
          {/* </LazyLoad> */}

          {/* <div className="facebook">
               <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FShowLoveProject%2F&width=300px&layout=standard&action=like&size=small&show_faces=true&share=true&height=800&appId=1602639390059106" className="iframe-element" scrolling="no" frameBorder="0" title="Show Love Project on Facebook"></iframe>
          </div> */}
          {/* <LazyLoad> */}
          {/* <div className="basicinfo contentbox section-img" style={{backgroundImage: "url(" + junitour + ")"}}></div> */}
          {/* <img className="section-image" src={junitour} alt="SLP Juni Tour 2019"/> */}
        {/* </LazyLoad> */}
        <Sociallinks />
        <RandomSongButton>

        </RandomSongButton>
        <div className="turt-sound-area">
          <RandomPlaybutton buttonText="" useChildren="true">
            <img src={turtsmall} className="turt-small-left" alt="Turti <3" />
          </RandomPlaybutton>
          {/* <RandomPlaybutton buttonText="Yo Turt, was sagst du dazu?"/> */}
          {/* <RandomPlaybutton buttonText="" useChildren="true">
              <img src={turtsmall} className="turt-small" alt="Turti <3" />
            </RandomPlaybutton> */}
          <div className="soundboardlink">
            <span role="img" aria-label="jsx-a11y/accessible-emoji">💚 </span><Link to="/soundboard">zum Show Love Soundboard</Link><span role="img" aria-label="jsx-a11y/accessible-emoji"> 💚</span>
          </div>
        </div>
        <footer>
          <div className="contentbox footer">
            <Link to="/impressum">Kontakt, Impressum & Datenschutz</Link>
          </div>
        </footer>
      </div>
    );
  }
}
